<template>
  <fieldset
    class="border rounded m-2 px-2 pt-2"
    :class="{ 'border-red-500': isError }"
  >
    <vxe-form
      v-if="!readType"
      class=""
      ref="anyForm"
      :data="table.formData"
      :rules="table.rules"
      titleAlign="right"
      @submit="table.save"
    >
      <vxe-form-item
        title="業務工號"
        field="JobTickeId"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <select-box
            v-if="data"
            v-bind="jobTickeSelectorOptions"
            :focusRefresh="true"
            v-model="data.JobTickeId"
          />
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="品號"
        field="JobTickeItemId"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <select-box
            v-bind="selectorOptions"
            v-model="data.JobTickeItemId"
            :focusRefresh="true"
            @change="customerProduct(data)"
          />
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="項次"
        field="JobTickeItemOrdinal"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-input
            type="text"
            v-model="data.JobTickeItemOrdinal"
            placeholder="請輸入文字"
            clearable
          ></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="客戶代號"
        field="CustomerId"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <select-box
            v-bind="customerSelectorOptions"
            v-model="data.CustomerId"
            :focusRefresh="true"
          />
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="品名"
        field="Name"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-input
            type="text"
            v-model="data.Name"
            placeholder="請輸入文字"
            clearable
          ></vxe-input>
        </template>
      </vxe-form-item>
      <!-- <vxe-form-item title="產品" field="ProductId" :item-render="{}" span="6">
          <template #default="{ data }">
            <select-box v-bind="productSelectorOptions" v-model="data.ProductId" @change="selectProduct(data)" />
          </template>
        </vxe-form-item> -->
      <vxe-form-item
        title="機台"
        field="MachineId"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <select-box
            v-bind="machineSelectorOptions"
            v-model="data.MachineId"
          />
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="日期"
        field="Time"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-input
            type="date"
            v-model="data.Time"
            placeholder="請輸入日期"
            label-format="yyyy/MM/dd"
            clearable
          ></vxe-input>
        </template>
      </vxe-form-item>
      <!-- <vxe-form-item title="機台" field="Machine" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.Machine" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="品名" field="Name" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.Name" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item> -->
      <!-- <vxe-form-item title="業務工號" field="WorkOrder" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.WorkOrder" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item> -->
      <vxe-form-item
        title="製令工號"
        field="ManufactureOrder"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-input
            type="text"
            v-model="data.ManufactureOrder"
            placeholder="請輸入文字"
            clearable
          ></vxe-input>
        </template>
      </vxe-form-item>
      <!-- <vxe-form-item title="品檢員" field="Inspector" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.Inspector" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item> -->
      <vxe-form-item
        field=""
        :item-render="{}"
        span="24"
      >
        工單規格/model
      </vxe-form-item>
      <!-- <vxe-form-item title="寬度(mm)" field="ModelWidth" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="float" v-model="data.ModelWidth" placeholder="請輸入數字" clearable></vxe-input>
          </template>
        </vxe-form-item> -->
      <vxe-form-item
        title="產品尺寸"
        field="WorkProductSize"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-input
            type="text"
            v-model="data.WorkProductSize"
            placeholder="請輸入文字"
            clearable
          ></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item
        field=""
        :item-render="{}"
        span="24"
      >
        寬度量測值(mm) ± 1
      </vxe-form-item>
      <vxe-form-item
        title="檢測1(mm)"
        field="Width1"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-input
            type="float"
            v-model="data.Width1"
            placeholder="請輸入數字"
            clearable
          ></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="檢測2(mm)"
        field="Width2"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-input
            type="float"
            v-model="data.Width2"
            placeholder="請輸入數字"
            clearable
          ></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="檢測3(mm)"
        field="Width3"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-input
            type="float"
            v-model="data.Width3"
            placeholder="請輸入數字"
            clearable
          ></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="卷徑(cm)"
        field="Roll"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-input
            type="float"
            v-model="data.Roll"
            placeholder="請輸入數字"
            clearable
          ></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="顏色"
        field="Color"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-input
            type="text"
            v-model="data.Color"
            placeholder="請輸入文字"
            clearable
          ></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="紙管水分 15%↓"
        field="Moisture"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-input
            type="float"
            v-model="data.Moisture"
            placeholder="請輸入數字"
            clearable
          ></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item
        :item-render="{}"
        span="3"
      >
        <template #default>
          <vxe-button
            type="submit"
            status="primary"
            :content="table.isEditing ? '修改' : '確認'"
          >
          </vxe-button>
          <vxe-button
            v-if="table.isEditing"
            type="button"
            @click="table.reset"
            status="danger"
            content="取消"
          >
          </vxe-button>
        </template>
      </vxe-form-item>
    </vxe-form>
    <vxe-table
      class="mb-2"
      ref="grid"
      :data="table.data"
      v-bind="gridOptions.props"
    >
      <vxe-column title="項次">
        <template #default="{ $rowIndex }">
          {{ $rowIndex + 1 }}
        </template>
      </vxe-column>
      <vxe-column
        field="Machine.Name"
        title="機台"
      >
      </vxe-column>
      <vxe-column
        field="Product.ProductName"
        title="品名"
        sortable
      >
      </vxe-column>
      <vxe-column
        field="Time"
        title="日期"
        :formatter="({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd')"
      >
      </vxe-column>
      <vxe-column
        field="JobTickeNo"
        title="業務工號"
        sortable
      >
      </vxe-column>
      <vxe-column
        field="ManufactureOrder"
        title="製令工號"
        sortable
      >
      </vxe-column>
      <vxe-column
        field="Inspector"
        title="品檢員"
        sortable
      >
      </vxe-column>
      <vxe-column
        v-if="!readType"
        title="操作"
        width="150"
      >
        <template #default="{ row }">
          <div class="flex justify-content">
            <vxe-button @click="table.update(row)">編輯</vxe-button>
            <vxe-button @click="table.delete(row)">刪除</vxe-button>
          </div>
        </template>
      </vxe-column>
    </vxe-table>
  </fieldset>
</template>
<script lang="ts">
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps, VxeFormProps } from "vxe-table";
import formatDate from 'xe-utils/toDateString'

export default defineComponent({
  name: "FlowTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    aid: Number,
    visible: Boolean,
    readType: Boolean,
    isError: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const jobId = ref(0);
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          multiple: true,
          defaultSort: []
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as any[],
      isLoading: false,
      isEditing: false,
      formData: {} as any,
      selectedRow: null as any | null,
      // insert() {
      //   table.formData = { OutSource: false, Fee: 0, PayStatus: 0, Ordinal: 0 };
      //   table.selectedRow = null;
      //   table.isEditing = true;
      // },
      update(row: any) {
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;
      },
      delete(row: any) {
        grid.value.remove(row);
        table.reset();
      },
      submit() {
        emit('submit', grid.value.getTableData().fullData)
      },
      reset() {
        table.formData = {} as any;
        table.selectedRow = null;
        table.isEditing = false;
      },
      async save() {
        table.formData.Product = null;
        if (!table.formData.ProductId)
          return CloudFun.send('error', { subject: '執行失敗', content: '請選擇產品' })
        if (table.formData.ProductId) {
          const temp = await model?.dispatch("product/find", table.formData.ProductId)
          table.formData.Product = { Id: temp.Id, ProductName: temp.ProductName, ProductNo: temp.ProductNo };
        }
        table.formData.Machine = null;
        if (!table.formData.MachineId)
          return CloudFun.send('error', { subject: '執行失敗', content: '請選擇機台' })
        if (table.formData.MachineId) {
          const machine = await model?.dispatch("machine/find", table.formData.MachineId)
          table.formData.Machine = { Id: machine.Id, Name: machine.Name, };
        }
        if (table.isEditing) {
          // 編輯確認
          Object.assign(table.selectedRow, table.formData);
        } else {
          // 新增確認
          await grid.value.insert(table.formData);
        }
        table.reset();
      },
      rules: {
        Number: [{ required: true }],
        Name: [{ required: true }],
        Time: [{ required: true }],
      }
    })

    watch(() => props.aid, async (value) => {
      if (value) {
        //console.log(props.data)
        if (props.data && props.data.length > 0) {
          table.data = props.data;
          table.data.forEach(async (e: any) => {
            if (e.ProductId) {
              const temp = await model?.dispatch("product/find", e.ProductId)
              e.Product = { Id: temp.Id, ProductName: temp.ProductName, ProductNo: temp.ProductNo };
            }
            if (e.MachineId) {
              const machine = await model?.dispatch("machine/find", e.MachineId)
              e.Machine = { Id: machine.Id, Name: machine.Name, };
            }
          });
        } else {
          table.data = [] as any;
        }
        table.reset();
      } else {
        table.data = [] as any;
        table.reset();
      }
    }, { immediate: true });

    const anyForm = ref<any>();

    const machineSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("machine/find", value),
        query: params => {
          return model!.dispatch("machine/query", params)
        }
      }
    };
    const jobTickeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "JobTickeNo",
      valueField: "Id",
      columns: [
        {
          field: "JobTickeNo",
          title: "工單號碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("jobTicke/find", value), // eslint-disable-line
        query: params => {
          return model!.dispatch("jobTicke/query", params) // eslint-disable-line
        }
      }
    };
    const productSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "ProductName",
      valueField: "Id",
      columns: [
        {
          field: "ProductNo",
          title: "品號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "ProductName",
          title: "品名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("product/find", value),
        query: params => {
          return model!.dispatch("product/query", params)
        }
      }
    };

    const jobTickeProduct = async (data: any) => {
      if (data?.WorkOrder) {
        try {
          data.jobticketId = data.WorkOrder;
          jobId.value = data.WorkOrder;
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        }
      }
    }

    const selectProduct = async (data: any) => {
      try {
        const product = await model!.dispatch('product/find', data.ProductId);
        console.log(product);
        if (product) {
          console.log('product OK');
          data.WorkProductSize = product.ProductSize;
          // data.WorkPaperType = product.TitlePaper;
          // data.WorkLight = product.ProductSize;
          // data.WorkTear = product.ProductSize;
          // data.WorkSound = product.ProductSize;
          // data.WorkColor = product.Color;
          // data.WorkOdor = product.ProductSize;
        }
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {

      }
    }


    const TF = [{ label: "是", value: true }, { label: "否", value: false }]

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Product.ProductNo",
      valueField: "Id",
      formatText: (row) => row.Product ? `${row.Product.ProductNo ?? ''}` : row.Id,
      columns: [
        {
          field: "JobTicke.JobTickeNo",
          title: "工單號碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Ordinal",
          title: "項次",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.ProductNo",
          title: "品號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.SKU",
          title: "SKU",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.ProductName",
          title: "品名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("jobTickeItem/find", value),
        query: params => {
          if (table.formData.JobTickeId) params.condition = new Condition("JobTickeId", Operator.Equal, table.formData.JobTickeId).and(params.condition!);
          return model!.dispatch("jobTickeItem/query", params)
        }
      }
    };

    const customerProduct = async (data: any) => {
      if (data?.JobTickeItemId) {
        try {
          const temp = await model?.dispatch("jobTickeItem/find", data.JobTickeItemId)
          if (temp) {
            data.ProductNumber = temp.Product?.ProductNo;
            data.WorkProductSize = temp.Product?.ProductSize;
            data.ProductId = temp.Product?.Id;
            data.Name = temp.Product?.ProductName;
            data.JobTickeId = temp.JobTicke.Id;
            data.JobTickeItemId = temp.Id;
            data.JobTickeNo = temp.JobTicke.JobTickeNo;
            data.JobTickeItemOrdinal = temp.Ordinal;
          }
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        }
      }
    }

    const customerSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("customer/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("customer/query", params) // eslint-disable-line
        }
      }
    };

    return {
      table,
      grid,
      gridOptions,
      anyForm,
      machineSelectorOptions,
      productSelectorOptions,
      model,
      TF,
      formatDate,
      jobTickeSelectorOptions,
      jobTickeProduct,
      selectProduct,
      selectorOptions,
      customerProduct,
      customerSelectorOptions,
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})
</script>

<template>
  <fieldset
    class="border rounded m-2 px-2 pt-2"
    :class="{ 'border-red-500': isError }"
  >
    <vxe-form
      v-if="!readType"
      class=""
      ref="anyForm"
      :data="table.formData"
      :rules="table.rules"
      titleAlign="right"
      @submit="table.save"
    >
      <vxe-form-item
        title="廠別"
        field="Factory"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-select
            v-model="data.Factory"
            placeholder="選擇"
            class="border rounded"
          >
            <vxe-option
              v-for="item in factorySelectorOptions"
              :key="item.value"
              :value="item.value"
              :label="`${item.label}`"
            ></vxe-option>
          </vxe-select>
          <!-- <vxe-input type="text" v-model="data.Company" placeholder="請輸入文字" clearable></vxe-input> -->
        </template>
      </vxe-form-item>
      <!-- <vxe-form-item title="站別/機台" field="Station" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.Station" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item> -->
      <vxe-form-item
        title="機台"
        field="Station"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <select-box
            v-bind="machineSelectorOptions"
            v-model="data.MachineId"
          />
        </template>
      </vxe-form-item>
      <!-- <vxe-form-item title="編號" field="Number" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.Number" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item> -->
      <vxe-form-item
        title="備註"
        field="Remark"
        :item-render="{}"
        span="12"
      >
        <template #default="{ data }">
          <vxe-input
            type="text"
            v-model="data.Remark"
            placeholder="請輸入文字"
            clearable
          ></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="品名(上午)"
        field="AmName"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-input
            type="text"
            v-model="data.AmName"
            placeholder="請輸入數字"
            clearable
          ></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="規格(上午)"
        field="AmSpecification"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-input
            type="text"
            v-model="data.AmSpecification"
            placeholder="請輸入文字"
            clearable
          ></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="品名(下午)"
        field="PmName"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-input
            type="text"
            v-model="data.PmName"
            placeholder="請輸入文字"
            clearable
          ></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="規格(下午)"
        field="PmSpecification"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-input
            type="text"
            v-model="data.PmSpecification"
            placeholder="請輸入文字"
            clearable
          ></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item
        :item-render="{}"
        span="3"
      >
        <template #default>
          <vxe-button
            type="submit"
            status="primary"
            :content="table.isEditing ? '修改' : '確認'"
          >
          </vxe-button>
          <vxe-button
            v-if="table.isEditing"
            type="button"
            @click="table.reset"
            status="danger"
            content="取消"
          >
          </vxe-button>
        </template>
      </vxe-form-item>
    </vxe-form>
    <vxe-table
      class="mb-2"
      ref="grid"
      :data="table.data"
      v-bind="gridOptions.props"
    >
      <vxe-column
        field="Factory"
        title="廠別"
      >
      </vxe-column>
      <vxe-column
        field="Station"
        title="站別/機台"
        sortable
      >
      </vxe-column>
      <vxe-column title="項次">
        <template #default="{ $rowIndex }">
          {{ $rowIndex + 1 }}
        </template>
      </vxe-column>
      <vxe-column
        field="AmName"
        title="品名(上午)"
        sortable
      >
      </vxe-column>
      <vxe-column
        field="AmSpecification"
        title="規格(上午)"
        sortable
      >
      </vxe-column>
      <vxe-column
        field="PmName"
        title="品名(下午)"
        sortable
      >
      </vxe-column>
      <vxe-column
        field="PmSpecification"
        title="規格(下午)"
        sortable
      >
      </vxe-column>
      <vxe-column
        field="Remark"
        title="備註"
        sortable
      >
      </vxe-column>
      <vxe-column
        v-if="!readType"
        title="操作"
        width="150"
      >
        <template #default="{ row }">
          <div class="flex justify-content">
            <vxe-button @click="table.update(row)">編輯</vxe-button>
            <vxe-button @click="table.delete(row)">刪除</vxe-button>
          </div>
        </template>
      </vxe-column>
    </vxe-table>
  </fieldset>
</template>
<script lang="ts">
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps, VxeFormProps } from "vxe-table";

export default defineComponent({
  name: "FlowTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    aid: Number,
    visible: Boolean,
    readType: Boolean,
    isError: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          multiple: true,
          defaultSort: []
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as any[],
      isLoading: false,
      isEditing: false,
      formData: {} as any,
      selectedRow: null as any | null,
      // insert() {
      //   table.formData = { OutSource: false, Fee: 0, PayStatus: 0, Ordinal: 0 };
      //   table.selectedRow = null;
      //   table.isEditing = true;
      // },
      update(row: any) {
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;
      },
      delete(row: any) {
        grid.value.remove(row);
        table.reset();
      },
      submit() {
        emit('submit', grid.value.getTableData().fullData)
      },
      reset() {
        table.formData = {} as any;
        table.selectedRow = null;
        table.isEditing = false;
      },
      async save() {
        if (table.isEditing) {
          // 編輯確認
          Object.assign(table.selectedRow, table.formData);
        } else {
          // 新增確認
          await grid.value.insert(table.formData);
        }
        table.reset();
      },
      rules: {
        Number: [{ required: true }],
        Name: [{ required: true }],
      }
    })

    watch(() => props.aid, async (value) => {
      if (value) {
        console.log(props.data)
        if (props.data && props.data.length > 0) {
          table.data = props.data;
        } else {
          table.data = [] as any;
        }
        table.reset();
      } else {
        table.data = [] as any;
        table.reset();
      }
    }, { immediate: true });

    const anyForm = ref<any>();

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("position/find", value),
        query: params => {
          return model!.dispatch("position/query", params)
        }
      }
    };
    const machineSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("machine/find", value),
        query: params => {
          return model!.dispatch("machine/query", params)
        }
      }
    };
    const factorySelectorOptions = [
      { label: "A廠", value: "A廠" },
      { label: "B廠", value: "B廠" },
      { label: "C廠", value: "C廠" },
      { label: "D廠", value: "D廠" },
      { label: "E廠", value: "E廠" },
      { label: "H廠", value: "H廠" },
    ];
    return {
      table,
      grid,
      gridOptions,
      anyForm,
      selectorOptions,
      model,
      machineSelectorOptions,
      factorySelectorOptions,
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})
</script>

<template>
  <fieldset
    class="border rounded m-2 px-2 pt-2"
    :class="{ 'border-red-500': isError }"
  >
    <vxe-form
      v-if="!readType"
      class=""
      ref="anyForm"
      :data="table.formData"
      :rules="table.rules"
      titleAlign="right"
      @submit="table.save"
    >
      <!-- <vxe-form-item title="工單號碼" field="Number" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.Number" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item> -->
      <vxe-form-item
        title="工單號碼"
        field="JobTickeId"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <select-box
            v-if="data"
            v-bind="jobTickeSelectorOptions"
            :focusRefresh="true"
            v-model="data.JobTickeId"
          />
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="品號"
        field="JobTickeItemId"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <select-box
            v-bind="selectorOptions"
            v-model="data.JobTickeItemId"
            :focusRefresh="true"
            @change="customerProduct(data)"
          />
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="項次"
        field="JobTickeItemOrdinal"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-input
            type="text"
            v-model="data.JobTickeItemOrdinal"
            placeholder="請輸入文字"
            clearable
          ></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="品名"
        field="Name"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-input
            type="text"
            v-model="data.Name"
            placeholder="請輸入文字"
            clearable
          ></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="規格/顏色"
        field="SKU"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-input
            type="text"
            v-model="data.SKU"
            placeholder="請輸入文字"
            clearable
          ></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="總量"
        field="Total"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-input
            type="number"
            v-model="data.Total"
            placeholder="請輸入數字"
            clearable
          ></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="抽驗數"
        field="TestCount"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-input
            type="number"
            v-model="data.TestCount"
            placeholder="請輸入數字"
            clearable
          ></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="致命缺陷"
        field="FatalFlaw"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-input
            type="text"
            v-model="data.FatalFlaw"
            placeholder="請輸入文字"
            clearable
          ></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="主要缺陷"
        field="MainFlaw"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-input
            type="text"
            v-model="data.MainFlaw"
            placeholder="請輸入文字"
            clearable
          ></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="次要缺陷"
        field="MinorFlaw"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-input
            type="text"
            v-model="data.MinorFlaw"
            placeholder="請輸入文字"
            clearable
          ></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="品質判定"
        field="Quality"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-select
            v-model="data.Quality"
            placeholder="類型"
            class="border rounded"
            clearable
          >
            <vxe-option
              v-for="item in qualityCategory"
              :key="item.value"
              :value="item.value"
              :label="`${item.label}`"
            ></vxe-option>
          </vxe-select>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="異常處理方式"
        field="ErrorHandle"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-input
            type="text"
            v-model="data.ErrorHandle"
            placeholder="請輸入文字"
            clearable
          ></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="備註"
        field="Remark"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-input
            type="text"
            v-model="data.Remark"
            placeholder="請輸入文字"
            clearable
          ></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item
        :item-render="{}"
        span="3"
      >
        <template #default>
          <vxe-button
            type="submit"
            status="primary"
            :content="table.isEditing ? '修改' : '確認'"
          >
          </vxe-button>
          <vxe-button
            v-if="table.isEditing"
            type="button"
            @click="table.reset"
            status="danger"
            content="取消"
          >
          </vxe-button>
        </template>
      </vxe-form-item>
    </vxe-form>
    <vxe-table
      class="mb-2"
      ref="grid"
      :data="table.data"
      v-bind="gridOptions.props"
    >
      <vxe-column
        field="JobTicke.JobTickeNo"
        title="工單號碼"
      >
      </vxe-column>
      <vxe-column
        field="ProductNumber"
        title="品號"
        sortable
      >
      </vxe-column>
      <vxe-column title="項次">
        <template #default="{ $rowIndex }">
          {{ $rowIndex + 1 }}
        </template>
      </vxe-column>
      <vxe-column
        field="SKU"
        title="規格/顏色"
        sortable
      >
      </vxe-column>
      <vxe-column
        field="Total"
        title="總量"
        sortable
      >
      </vxe-column>
      <vxe-column
        field="TestCount"
        title="抽驗數"
        sortable
      >
      </vxe-column>
      <vxe-column
        field="FatalFlaw"
        title="致命缺陷"
        sortable
      >
      </vxe-column>
      <vxe-column
        field="MainFlaw"
        title="主要缺陷"
        sortable
      >
      </vxe-column>
      <vxe-column
        field="MinorFlaw"
        title="次要缺陷"
        sortable
      >
      </vxe-column>
      <vxe-column
        field="Quality"
        title="品質判定"
        sortable
      >
      </vxe-column>
      <vxe-column
        field="ErrorHandle"
        title="異常處理方式"
        sortable
      >
      </vxe-column>
      <vxe-column
        field="Remark"
        title="備註"
        sortable
      >
      </vxe-column>
      <vxe-column
        v-if="!readType"
        title="操作"
        width="150"
      >
        <template #default="{ row }">
          <div class="flex justify-content">
            <vxe-button @click="table.update(row)">編輯</vxe-button>
            <vxe-button @click="table.delete(row)">刪除</vxe-button>
          </div>
        </template>
      </vxe-column>
    </vxe-table>
    <div class="m-2">
      <div>※備註</div>
      <div>依據ISO2859-1規範之Ⅱ級一般檢驗水平的正常抽樣計畫進行產品檢驗，AQL允收標準為：致命缺陷(不允許)，主要缺陷(1.0)，次要缺陷(4.0)。</div>
      <div>缺陷分類：</div>
      <div>1. 致命缺陷： ① 膠帶：嚴重髒污、異物或成型不良、染色 ② 標籤紙：未貼或貼錯 ③ 切台：功能喪失 ④ 內容物或數量不符</div>
      <div>2. 主要缺陷： ① 膠帶：些微髒污、異物或成型不良 ② 標籤紙：些微髒汙或皺褶 ③ 收縮膜：些微破損或異物 ④ 切台：表面些微擦挫傷</div>
      <div>3. 次要缺陷： ① 膠帶：輕微髒污、異物或成型不良 ② 標籤紙：輕微髒汙或皺褶 ③ 收縮膜：輕微破損或異物 ④ 切台：表面輕微擦挫傷</div>
    </div>
  </fieldset>
</template>
<script lang="ts">
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps, VxeFormProps } from "vxe-table";
import formatDate from 'xe-utils/toDateString';
import { InspectionQuantity } from "@/libs/utils";

export default defineComponent({
  name: "FlowTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    aid: Number,
    contentType: Number,
    customerId: Number,
    visible: Boolean,
    readType: Boolean,
    isError: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          multiple: true,
          defaultSort: []
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as any[],
      isLoading: false,
      isEditing: false,
      formData: {} as any,
      selectedRow: null as any | null,
      // insert() {
      //   table.formData = { OutSource: false, Fee: 0, PayStatus: 0, Ordinal: 0 };
      //   table.selectedRow = null;
      //   table.isEditing = true;
      // },
      update(row: any) {
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;
      },
      delete(row: any) {
        grid.value.remove(row);
        table.reset();
      },
      submit() {
        emit('submit', grid.value.getTableData().fullData)
      },
      reset() {
        table.formData = {} as any;
        table.selectedRow = null;
        table.formData.TestCount = 0;
        table.formData.Total = 0;
        table.isEditing = false;
      },
      async save() {

        table.formData.JobTicke = null;
        if (!table.formData.JobTickeId)
          return CloudFun.send('error', { subject: '執行失敗', content: '請選擇項目' })

        if (table.formData.JobTickeId) {
          const temp = await model?.dispatch("jobTicke/find", table.formData.JobTickeId);
          table.formData.JobTicke = { JobTickeNo: temp.JobTickeNo };
        }

        if (table.isEditing) {
          // 編輯確認
          Object.assign(table.selectedRow, table.formData);
        } else {
          // 新增確認
          await grid.value.insert(table.formData);
        }
        table.reset();
      },
      rules: {
        Number: [{ required: true }],
        Name: [{ required: true }],
      }
    })

    watch(() => props.aid, async (value) => {
      if (value) {
        if (props.data && props.data.length > 0) {
          table.data = props.data;
          table.data.forEach(async (e: any) => {
            if (e.JobTickeId) {
              const temp = await model?.dispatch("jobTicke/find", e.JobTickeId);
              e.JobTicke = { JobTickeNo: temp.JobTickeNo };
            }
          });
        } else {
          table.data = [] as any;
        }
        table.reset();
      } else {
        table.data = [] as any;
        table.reset();
      }
    }, { immediate: true });

    watch(() => table.formData.Total, async (value) => {
      table.formData.TestCount = InspectionQuantity(value);
    }, { immediate: true });

    const anyForm = ref<any>();

    const TF = [{ label: "是", value: true }, { label: "否", value: false }]

    const colorBoxCategory = [
      { label: "包裝盒", value: "包裝盒" },
      { label: "車型盒", value: "車型盒" },
      { label: "切台盒", value: "切台盒" },
      { label: "內盒", value: "內盒" },
      { label: "托盒", value: "托盒" },
      { label: "吊盒", value: "吊盒" },
    ]

    const cartonCategory = [
      { label: "外箱", value: "外箱" },
      { label: "內箱", value: "內箱" },
      { label: "其他", value: "其他" },
    ]

    const qualityCategory = [
      { label: "合格", value: "合格" },
      { label: "不合格", value: "不合格" },
    ]

    const jobTickeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "JobTickeNo",
      valueField: "Id",
      columns: [
        {
          field: "JobTickeNo",
          title: "工單號碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("jobTicke/find", value), // eslint-disable-line
        query: params => {
          if (props.customerId) params.condition = new Condition("CustomerId", Operator.Equal, props.customerId).and(params.condition!);
          return model!.dispatch("jobTicke/query", params) // eslint-disable-line
        }
      }
    };

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Product.ProductNo",
      valueField: "Id",
      formatText: (row) => row.Product ? `${row.Product.ProductNo ?? ''}` : row.Id,
      columns: [
        {
          field: "JobTicke.JobTickeNo",
          title: "工單號碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Ordinal",
          title: "項次",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.ProductNo",
          title: "品號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.SKU",
          title: "SKU",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.ProductName",
          title: "品名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("jobTickeItem/find", value),
        query: params => {
          if (table.formData.JobTickeId) params.condition = new Condition("JobTickeId", Operator.Equal, table.formData.JobTickeId).and(params.condition!);
          return model!.dispatch("jobTickeItem/query", params)
        }
      }
    };

    const customerProduct = async (data: any) => {
      if (data?.JobTickeItemId) {
        try {
          const temp = await model?.dispatch("jobTickeItem/find", data.JobTickeItemId)
          if (temp) {
            data.ProductNumber = temp.Product?.ProductNo;
            data.Name = temp.Product?.ProductName;
            data.SKU = temp.Product?.ProductSize + ' / ' + temp.Product?.Color;
            data.JobTickeId = temp.JobTicke.Id;
            data.JobTickeItemId = temp.Id;
            data.JobTickeNo = temp.JobTicke.JobTickeNo;
            data.JobTickeItemOrdinal = temp.Ordinal;
          }
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        }
      }
    }

    return {
      table,
      grid,
      gridOptions,
      anyForm,
      selectorOptions,
      model,
      TF,
      colorBoxCategory,
      cartonCategory,
      formatDate,
      jobTickeSelectorOptions,
      customerProduct,
      qualityCategory,
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})
</script>


/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps, VxeFormProps } from "vxe-table";
import formatDate from 'xe-utils/toDateString';

export default defineComponent({
  name: "FlowTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    aid: Number,
    contentType: Number,
    visible: Boolean,
    readType: Boolean,
    isError: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          multiple: true,
          defaultSort: []
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as any[],
      isLoading: false,
      isEditing: false,
      formData: {} as any,
      selectedRow: null as any | null,
      // insert() {
      //   table.formData = { OutSource: false, Fee: 0, PayStatus: 0, Ordinal: 0 };
      //   table.selectedRow = null;
      //   table.isEditing = true;
      // },
      update(row: any) {
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;
      },
      delete(row: any) {
        grid.value.remove(row);
        table.reset();
      },
      submit() {
        emit('submit', grid.value.getTableData().fullData)
      },
      reset() {
        table.formData = {} as any;
        table.selectedRow = null;
        table.formData.ShrinkFilm = false;
        table.formData.CandyBag = false;
        table.formData.SealingBag = false;
        table.formData.PackingBag = false;
        table.formData.PVC = false;
        table.formData.POF = false;
        table.formData.PE = false;
        table.formData.Cutter = false;
        table.formData.ColorBox = false;
        table.formData.Elevator = false;
        table.formData.PaperCard = false;
        table.formData.Sticker = "貼紙";
        table.formData.InnerBox = false;
        table.formData.Carton = false;
        table.formData.ReleasePaper = false;
        table.formData.RoundCard = false;
        table.formData.TuckBag = false;
        table.formData.WhiteCopperCard = false;
        table.formData.Tray = false;
        table.formData.First = false;
        table.formData.Middle = false;
        table.formData.Last = false;
        if (props.contentType == 17) {
          table.formData.Category = '標籤';
        }
        table.isEditing = false;
      },
      async save() {

        // table.formData.JobTickeItem = null;
        // if(!table.formData.JobTickeItemId)
        //   return CloudFun.send('error', { subject: '執行失敗', content: '請選擇產品' })
        // if (table.formData.JobTickeItemId) {
        //   const temp = await model?.dispatch("jobTickeItem/find", table.formData.JobTickeItemId)
        //   table.formData.JobTickeItem = { Id: temp.Id, ProductName: temp.Product.ProductName, ProductNo: temp.Product.ProductNo };
        // }

        if (table.isEditing) {
          // 編輯確認
          Object.assign(table.selectedRow, table.formData);
        } else {
          // 新增確認
          await grid.value.insert(table.formData);
        }
        table.reset();
      },
      rules: {
        Number: [{ required: true }],
        Name: [{ required: true }],
        Date: [{ required: true }],
      }
    })

    watch(() => props.aid, async (value) => {
      if (value) {
        //console.log(props.contentType)
        if (props.data && props.data.length > 0) {
          table.data = props.data;
          // table.data.forEach(async (e: any) => {
          //   if(e.JobTickeItemId) {
          //     const temp = await model?.dispatch("jobTickeItem/find", e.JobTickeItemId);
          //     e.JobTickeItem = { Id: temp.Id, ProductName: temp.Product.ProductName, ProductNo: temp.Product.ProductNo };
          //   }
          // });
        } else {
          table.data = [] as any;
        }
        table.reset();
      } else {
        table.data = [] as any;
        table.reset();
      }
    }, { immediate: true });

    const anyForm = ref<any>();

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Product.ProductNo",
      valueField: "Id",
      formatText: (row) => row.Product ? `${row.Product.ProductNo ?? ''}` : row.Id,
      columns: [
        {
          field: "JobTicke.JobTickeNo",
          title: "工單號碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Ordinal",
          title: "項次",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.ProductNo",
          title: "品號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.SKU",
          title: "SKU",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.ProductName",
          title: "品名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("jobTickeItem/find", value),
        query: params => {
          if (table.formData.JobTickeId) params.condition = new Condition("JobTickeId", Operator.Equal, table.formData.JobTickeId).and(params.condition!);
          return model!.dispatch("jobTickeItem/query", params)
        }
      }
    };

    const TF = [{ label: "是", value: true }, { label: "否", value: false }]

    const colorBoxCategory = [
      { label: "包裝盒", value: "包裝盒" },
      { label: "車型盒", value: "車型盒" },
      { label: "切台盒", value: "切台盒" },
      { label: "內盒", value: "內盒" },
      { label: "托盒", value: "托盒" },
      { label: "吊盒", value: "吊盒" },
    ]

    const cartonCategory = [
      { label: "外箱", value: "外箱" },
      { label: "內箱", value: "內箱" },
      { label: "其他", value: "其他" },
    ]

    const shrinkFilmType = [
      { label: "PVC收縮膜", value: "PVC收縮膜" },
      { label: "POF收縮膜", value: "POF收縮膜" },
      { label: "PE收縮膜", value: "PE收縮膜" },
    ]

    const stickerType = [
      { label: "貼紙", value: "貼紙" },
      { label: "條碼貼紙", value: "條碼貼紙" },
      { label: "透明圓貼", value: "透明圓貼" },
      { label: "客供貼紙", value: "客供貼紙" },
    ]

    const packingBagType = [
      { label: "糖果袋", value: "糖果袋" },
      { label: "打孔自黏袋", value: "打孔自黏袋" },
      { label: "平口塑膠袋", value: "平口塑膠袋" },
      { label: "空白自黏袋", value: "空白自黏袋" },
      { label: "PE折角袋", value: "PE折角袋" },
    ]


    const customerProduct = async (data: any) => {
      if (data?.JobTickeItemId) {
        try {
          const temp = await model?.dispatch("jobTickeItem/find", data.JobTickeItemId)
          if (temp) {
            data.ProductName = temp.Product?.ProductName;
            data.ProductNumber = temp.Product?.ProductNo;
            data.SKU = temp.Product?.ProductSize + ' / ' + temp.Product?.Color;
            data.JobTickeId = temp.JobTicke.Id;
            data.JobTickeItemId = temp.Id;
            data.JobTickeNo = temp.JobTicke.JobTickeNo;
            data.JobTickeItemOrdinal = temp.Ordinal;
          }
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        }
      }
    }

    const jobTickeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "JobTickeNo",
      valueField: "Id",
      columns: [
        {
          field: "JobTickeNo",
          title: "工單號碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("jobTicke/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("jobTicke/query", params) // eslint-disable-line
        }
      }
    };

    return {
      table,
      grid,
      gridOptions,
      anyForm,
      selectorOptions,
      model,
      TF,
      colorBoxCategory,
      cartonCategory,
      formatDate,
      customerProduct,
      jobTickeSelectorOptions,
      shrinkFilmType,
      stickerType,
      packingBagType,
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})

<template>
  <fieldset class="border rounded m-2 px-2 pt-2">
    <vxe-form v-if="!readType" class="" ref="anyForm" :data="table.formData" :rules="table.rules" titleAlign="right" @submit="table.save">
        <vxe-form-item title="項次" field="Item" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.Item" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="編號" field="Number" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.Number" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="樣品進度/備註" field="Remark" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.Remark" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="完成日期" field="FinishedDate" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="date" v-model="data.FinishedDate" placeholder="請輸入日期" label-format="yyyy/MM/dd" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="交予人員" field="Name" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.Name" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="規格" field="Specification" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-select v-model="data.Specification">
              <vxe-option v-for="item in TF" :key="item.value" :value="item.value" :label="`${item.label}`"></vxe-option>
            </vxe-select>
          </template>
        </vxe-form-item>
        <vxe-form-item title="外觀" field="Exterior" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-select v-model="data.Exterior">
              <vxe-option v-for="item in TF" :key="item.value" :value="item.value" :label="`${item.label}`"></vxe-option>
            </vxe-select>
          </template>
        </vxe-form-item>
        <vxe-form-item title="包裝" field="Package" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-select v-model="data.Package">
              <vxe-option v-for="item in TF" :key="item.value" :value="item.value" :label="`${item.label}`"></vxe-option>
            </vxe-select>
          </template>
        </vxe-form-item>
        <vxe-form-item title="物性" field="Property" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-select v-model="data.Property">
              <vxe-option v-for="item in TF" :key="item.value" :value="item.value" :label="`${item.label}`"></vxe-option>
            </vxe-select>
          </template>
        </vxe-form-item>
        <vxe-form-item title="已完成並寄出" field="Finished" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-select v-model="data.Finished">
              <vxe-option v-for="item in TF" :key="item.value" :value="item.value" :label="`${item.label}`"></vxe-option>
            </vxe-select>
          </template>
        </vxe-form-item>
        <vxe-form-item :item-render="{}" span="3">
          <template #default>
            <vxe-button type="submit" status="primary" :content="table.isEditing ? '修改' : '確認'">
            </vxe-button>
            <vxe-button v-if="table.isEditing" type="button" @click="table.reset" status="danger" content="取消">
            </vxe-button>
          </template>
        </vxe-form-item>
    </vxe-form>
    <vxe-table class="mb-2" ref="grid" :data="table.data" v-bind="gridOptions.props">
      <vxe-column field="Item" title="項次" sortable>
      </vxe-column>
      <vxe-column field="Number" title="編號">
      </vxe-column>
      <vxe-column field="Remark" title="樣品進度/備註" sortable>
      </vxe-column>
      <vxe-column field="FinishedDate" title="完成日期" :formatter="({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd')">
      </vxe-column>
      <vxe-column field="Name" title="交予人員" sortable>
      </vxe-column>
      <vxe-column field="Specification" title="規格" sortable :formatter="({ cellValue }) => cellValue == true ? '是' : cellValue == false ? '否' : '不選擇'">
      </vxe-column>
      <vxe-column field="Exterior" title="外觀" sortable :formatter="({ cellValue }) => cellValue == true ? '是' : cellValue == false ? '否' : '不選擇'">
      </vxe-column>
      <vxe-column field="Package" title="包裝" sortable :formatter="({ cellValue }) => cellValue == true ? '是' : cellValue == false ? '否' : '不選擇'">
      </vxe-column>
      <vxe-column field="Property" title="物性" sortable :formatter="({ cellValue }) => cellValue == true ? '是' : cellValue == false ? '否' : '不選擇'">
      </vxe-column>
      <vxe-column field="Finished" title="已完成並寄出" sortable :formatter="({ cellValue }) => cellValue == true ? '是' : cellValue == false ? '否' : '不選擇'">
      </vxe-column>
      <vxe-column v-if="!readType" title="操作" width="150">
        <template #default="{ row }">
          <div class="flex justify-content">
            <vxe-button @click="table.update(row)">編輯</vxe-button>
            <vxe-button @click="table.delete(row)">刪除</vxe-button>
          </div>
        </template>
      </vxe-column>
    </vxe-table>
  </fieldset>
</template>
<script lang="ts">
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps, VxeFormProps } from "vxe-table";
import formatDate from 'xe-utils/toDateString';

export default defineComponent({
  name: "FlowTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    aid: Number,
    contentType: Number,
    visible: Boolean,
    readType: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          multiple: true,
          defaultSort: []
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as any[],
      isLoading: false,
      isEditing: false,
      formData: {  } as any,
      selectedRow: null as any | null,
      // insert() {
      //   table.formData = { OutSource: false, Fee: 0, PayStatus: 0, Ordinal: 0 };
      //   table.selectedRow = null;
      //   table.isEditing = true;
      // },
      update(row: any) {
        table.formData = { ...row };
        table.selectedRow = row;        
        table.isEditing = true;
      },
      delete(row: any) {
        grid.value.remove(row);
        table.reset();
      },
      submit() {
        emit('submit', grid.value.getTableData().fullData)
      },
      reset() {
        table.formData = {} as any;
        table.selectedRow = null;
        table.formData.Specification = false;
        table.formData.Exterior = false;
        table.formData.Package = false;
        table.formData.Property = false;
        table.formData.Finished = false;
        table.isEditing = false;
      },
      async save() {
        if(table.isEditing) {
          // 編輯確認
          Object.assign(table.selectedRow, table.formData);
        } else {
          // 新增確認
          await grid.value.insert(table.formData);
        }
        table.reset();
      },
      rules: {
        Number: [{ required: true }],
        Name: [{ required: true }],
        FinishedDate: [{ required: true }],
      }
    })

    watch(() => props.aid, async(value) => {
      if(value) {
        console.log(props.contentType)
        if(props.data && props.data.length > 0) {
          table.data = props.data;
        } else {
          table.data = [] as any;
        }
        table.reset();
      } else {
        table.data = [] as any;
        table.reset();
      }
    }, { immediate: true });

    const anyForm = ref<any>();

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("position/find", value),
        query: params => {
          return model!.dispatch("position/query", params)
        }
      }
    };

    const TF = [{ label: "是", value: true }, { label: "否", value: false }]

    const colorBoxCategory = [
      { label: "包裝盒", value: "包裝盒" },
      { label: "車型盒", value: "車型盒" },
      { label: "切台盒", value: "切台盒" },
      { label: "內盒", value: "內盒" },
      { label: "托盒", value: "托盒" },
      { label: "吊盒", value: "吊盒" },
    ]

    const cartonCategory = [
      { label: "外箱", value: "外箱" },
      { label: "內箱", value: "內箱" },
      { label: "其他", value: "其他" },
    ]

    return {
      table,
      grid,
      gridOptions,
      anyForm,
      selectorOptions,
      model,
      TF,
      colorBoxCategory,
      cartonCategory,
      formatDate,
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})
</script>

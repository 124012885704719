<template>
  <fieldset class="border rounded m-2 px-2 pt-2">
    <vxe-form v-if="!readType" class="" ref="anyForm" :data="table.formData" :rules="table.rules" titleAlign="right" @submit="table.save">
        <!-- 採購單號 Number
        供應商 Supplier
        類型 Category
        品名/料號 Name
        規格 Specification
        來料數量 IncomingCount
        檢驗數量 TestCount
        異常單號 Abnormal
        長±0.5mm Length
        寬±0.5mm Width
        高±0.5mm Height
        印刷 Print
        顏色 Color
        文字 Word
        條碼 Barcode
        材質 Material
        合格 Qualified
        檢驗人員 TestName -->
        <vxe-form-item title="採購單號" field="Number" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.Number" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="供應商" field="Supplier" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.Supplier" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item v-if="contentType == 17  || contentType == 20" title="類型" field="Category" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.Category" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item v-if="contentType == 18" title="類型" field="Category" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-select v-model="data.Category" placeholder="類型" clearable>
              <vxe-option v-for="item in colorBoxCategory" :key="item.value" :value="item.value" :label="`${item.label}`"></vxe-option>
            </vxe-select>
          </template>
        </vxe-form-item>
        <vxe-form-item v-if="contentType == 19" title="類型" field="Category" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-select v-model="data.Category" placeholder="類型" clearable>
              <vxe-option v-for="item in cartonCategory" :key="item.value" :value="item.value" :label="`${item.label}`"></vxe-option>
            </vxe-select>
          </template>
        </vxe-form-item>
        <vxe-form-item v-if="contentType == 19" title="其他(類型)" field="OtherCategory" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.OtherCategory" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="品名/料號" field="Name" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.Name" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="規格" field="Specification" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.Specification" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="來料數量" field="IncomingCount" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="number" v-model="data.IncomingCount" placeholder="請輸入數字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="檢驗數量" field="TestCount" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="number" v-model="data.TestCount" placeholder="請輸入數字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item :title="contentType == 19 ? '長(mm)' : '長±0.5mm'" field="Length" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="number" v-model="data.Length" placeholder="請輸入數字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item :title="contentType == 19 ? '寬(mm)' : '寬±0.5mm'" field="Width" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="number" v-model="data.Width" placeholder="請輸入數字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item :title="contentType == 19 ? '高(mm)' : '高±0.5mm'" field="Height" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="number" v-model="data.Height" placeholder="請輸入數字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="印刷" field="Print" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-select v-model="data.Print" placeholder="印刷" clearable>
              <vxe-option v-for="item in TF" :key="item.value" :value="item.value" :label="`${item.label}`"></vxe-option>
            </vxe-select>
          </template>
        </vxe-form-item>
        <vxe-form-item title="顏色" field="Color" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-select v-model="data.Color" placeholder="顏色" clearable>
              <vxe-option v-for="item in TF" :key="item.value" :value="item.value" :label="`${item.label}`"></vxe-option>
            </vxe-select>
          </template>
        </vxe-form-item>
        <vxe-form-item title="文字" field="Word" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-select v-model="data.Word" placeholder="文字" clearable>
              <vxe-option v-for="item in TF" :key="item.value" :value="item.value" :label="`${item.label}`"></vxe-option>
            </vxe-select>
          </template>
        </vxe-form-item>
        <vxe-form-item title="條碼" field="Barcode" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-select v-model="data.Barcode" placeholder="條碼" clearable>
              <vxe-option v-for="item in TF" :key="item.value" :value="item.value" :label="`${item.label}`"></vxe-option>
            </vxe-select>
          </template>
        </vxe-form-item>
        <vxe-form-item title="材質" field="Material" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-select v-model="data.Material" placeholder="材質" clearable>
              <vxe-option v-for="item in TF" :key="item.value" :value="item.value" :label="`${item.label}`"></vxe-option>
            </vxe-select>
          </template>
        </vxe-form-item>
        <vxe-form-item title="合格" field="Qualified" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-select v-model="data.Qualified" placeholder="合格" clearable>
              <vxe-option v-for="item in TF" :key="item.value" :value="item.value" :label="`${item.label}`"></vxe-option>
            </vxe-select>
          </template>
        </vxe-form-item>
        <vxe-form-item title="異常單號" field="Abnormal" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.Abnormal" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="檢驗人員" field="TestName" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.TestName" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item :item-render="{}" span="3">
          <template #default>
            <vxe-button type="submit" status="primary" :content="table.isEditing ? '修改' : '確認'">
            </vxe-button>
            <vxe-button v-if="table.isEditing" type="button" @click="table.reset" status="danger" content="取消">
            </vxe-button>
          </template>
        </vxe-form-item>
    </vxe-form>
    <vxe-table class="mb-2" ref="grid" :data="table.data" v-bind="gridOptions.props">
      <vxe-column field="Number" title="採購單號">
      </vxe-column>
      <vxe-column field="Supplier" title="供應商" sortable>
      </vxe-column>
      <vxe-column field="Category" title="類型" sortable>
      </vxe-column>
      <vxe-column field="OtherCategory" title="其他(類型)" sortable>
      </vxe-column>
      <vxe-column field="Name" title="品名/料號" sortable>
      </vxe-column>
      <vxe-column field="Specification" title="規格" sortable>
      </vxe-column>
      <vxe-column field="IncomingCount" title="來料數量" sortable>
      </vxe-column>
      <vxe-column field="TestCount" title="檢驗數量" sortable>
      </vxe-column>
      <vxe-column field="Length" :title="contentType == 19 ? '長(mm)' : '長±0.5mm'" sortable>
      </vxe-column>
      <vxe-column field="Width" :title="contentType == 19 ? '寬(mm)' : '寬±0.5mm'" sortable>
      </vxe-column>
      <vxe-column field="Height" :title="contentType == 19 ? '高(mm)' : '高±0.5mm'" sortable>
      </vxe-column>
      <vxe-column field="Print" title="印刷" sortable :formatter="({ cellValue }) => cellValue == true ? '是' : cellValue == false ? '否' : '不選擇'">
      </vxe-column>
      <vxe-column field="Color" title="顏色" sortable :formatter="({ cellValue }) => cellValue == true ? '是' : cellValue == false ? '否' : '不選擇'">
      </vxe-column>
      <vxe-column field="Word" title="文字" sortable :formatter="({ cellValue }) => cellValue == true ? '是' : cellValue == false ? '否' : '不選擇'">
      </vxe-column>
      <vxe-column field="Barcode" title="條碼" sortable :formatter="({ cellValue }) => cellValue == true ? '是' : cellValue == false ? '否' : '不選擇'">
      </vxe-column>
      <vxe-column field="Material" title="材質" sortable :formatter="({ cellValue }) => cellValue == true ? '是' : cellValue == false ? '否' : '不選擇'">
      </vxe-column>
      <vxe-column field="Qualified" title="合格" sortable :formatter="({ cellValue }) => cellValue == true ? '是' : cellValue == false ? '否' : '不選擇'">
      </vxe-column>
      <vxe-column field="Abnormal" title="異常單號" sortable>
      </vxe-column>
      <vxe-column field="TestName" title="檢驗人員" sortable>
      </vxe-column>
      <vxe-column v-if="!readType" title="操作" width="150">
        <template #default="{ row }">
          <div class="flex justify-content">
            <vxe-button @click="table.update(row)">編輯</vxe-button>
            <vxe-button @click="table.delete(row)">刪除</vxe-button>
          </div>
        </template>
      </vxe-column>
    </vxe-table>
  </fieldset>
</template>
<script lang="ts">
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps, VxeFormProps } from "vxe-table";
import { InspectionQuantity } from "@/libs/utils";

export default defineComponent({
  name: "FlowTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    aid: Number,
    contentType: Number,
    visible: Boolean,
    readType: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          multiple: true,
          defaultSort: []
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as any[],
      isLoading: false,
      isEditing: false,
      formData: {  } as any,
      selectedRow: null as any | null,
      // insert() {
      //   table.formData = { OutSource: false, Fee: 0, PayStatus: 0, Ordinal: 0 };
      //   table.selectedRow = null;
      //   table.isEditing = true;
      // },
      update(row: any) {
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;
      },
      delete(row: any) {
        grid.value.remove(row);
        table.reset();
      },
      submit() {
        emit('submit', grid.value.getTableData().fullData)
      },
      reset() {
        table.formData = {} as any;
        table.selectedRow = null;
        table.formData.IncomingCount = 0;
        table.formData.TestCount = 0;
        table.formData.Length = 0;
        table.formData.Width = 0;
        table.formData.Height = 0;
        table.formData.Print = false;
        table.formData.Color = false;
        table.formData.Word = false;
        table.formData.Barcode = false;
        table.formData.Material = false;
        table.formData.Qualified = false;
        if(props.contentType == 17) {
          table.formData.Category = '標籤';
        }
        table.isEditing = false;
      },
      async save() {
        if(table.isEditing) {
          // 編輯確認
          Object.assign(table.selectedRow, table.formData);
        } else {
          // 新增確認
          await grid.value.insert(table.formData);
        }
        table.reset();
      },
      rules: {
        Number: [{ required: true }],
        Name: [{ required: true }],
      }
    })

    watch(() => props.aid, async(value) => {
      if(value) {
        console.log(props.contentType)
        if(props.data && props.data.length > 0) {
          table.data = props.data;
        } else {
          table.data = [] as any;
        }
        table.reset();
      } else {
        table.data = [] as any;
        table.reset();
      }
    }, { immediate: true });

    watch(() => table.formData.IncomingCount, async(value) => {
      table.formData.TestCount = InspectionQuantity(value);
    }, { immediate: true });

    const anyForm = ref<any>();

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("position/find", value),
        query: params => {
          return model!.dispatch("position/query", params)
        }
      }
    };

    const TF = [{ label: "是", value: true }, { label: "否", value: false }]

    const colorBoxCategory = [
      { label: "包裝盒", value: "包裝盒" },
      { label: "車型盒", value: "車型盒" },
      { label: "切台盒", value: "切台盒" },
      { label: "內盒", value: "內盒" },
      { label: "托盒", value: "托盒" },
      { label: "吊盒", value: "吊盒" },
    ]

    const cartonCategory = [
      { label: "外箱", value: "外箱" },
      { label: "內箱", value: "內箱" },
      { label: "其他", value: "其他" },
    ]

    return {
      table,
      grid,
      gridOptions,
      anyForm,
      selectorOptions,
      model,
      TF,
      colorBoxCategory,
      cartonCategory,
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})
</script>


/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps, VxeFormProps } from "vxe-table";

export default defineComponent({
  name: "FlowTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    aid: Number,
    visible: Boolean,
    readType: Boolean,
    jobticketId: Number,
    isError: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const jobId = ref(0);
    const checkPaperType = [
      { label: "翹頭", value: "翹頭" },
      { label: "不翹頭", value: "不翹頭" },
    ]

    const gridOptions = {
      props: {
        sortConfig: {
          multiple: true,
          defaultSort: []
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as any[],
      isLoading: false,
      isEditing: false,
      formData: {} as any,
      selectedRow: null as any | null,
      // insert() {
      //   table.formData = { OutSource: false, Fee: 0, PayStatus: 0, Ordinal: 0 };
      //   table.selectedRow = null;
      //   table.isEditing = true;
      // },
      update(row: any) {
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;
      },
      delete(row: any) {
        grid.value.remove(row);
        table.reset();
      },
      submit() {
        emit('submit', grid.value.getTableData().fullData)
      },
      reset() {
        table.formData = {} as any;
        table.formData.WorkLight = false;
        table.formData.WorkTear = false;
        table.formData.WorkSound = false;
        table.formData.Light = false;
        table.formData.Tear = false;
        table.formData.Sound = false;
        table.selectedRow = null;
        table.isEditing = false;
      },
      async save() {
        table.formData.Product = null;
        if (!table.formData.ProductId)
          return CloudFun.send('error', { subject: '執行失敗', content: '請選擇產品' })
        if (table.formData.ProductId) {
          const temp = await model?.dispatch("product/find", table.formData.ProductId)
          table.formData.Product = { Id: temp.Id, ProductName: temp.ProductName, ProductNo: temp.ProductNo };
        }
        table.formData.Machine = null;
        if (!table.formData.MachineId)
          return CloudFun.send('error', { subject: '執行失敗', content: '請選擇機台' })
        if (table.formData.MachineId) {
          const machine = await model?.dispatch("machine/find", table.formData.MachineId)
          table.formData.Machine = { Id: machine.Id, Name: machine.Name, };
        }

        if (table.isEditing) {
          // 編輯確認
          Object.assign(table.selectedRow, table.formData);
        } else {
          // 新增確認
          await grid.value.insert(table.formData);
        }
        table.reset();
      },
      rules: {
        Number: [{ required: true }],
        Name: [{ required: true }],
      }
    })

    watch(() => props.aid, async (value) => {
      if (value) {
        //console.log(props.data)
        if (props.data && props.data.length > 0) {
          table.data = props.data;
          table.data.forEach(async (e: any) => {
            if (e.ProductId) {
              const temp = await model?.dispatch("product/find", e.ProductId)
              e.Product = { Id: temp.Id, ProductName: temp.ProductName, ProductNo: temp.ProductNo };
            }
            if (e.MachineId) {
              const machine = await model?.dispatch("machine/find", e.MachineId)
              e.Machine = { Id: machine.Id, Name: machine.Name, };
            }
          });
        } else {
          table.data = [] as any;
        }
        table.reset();
      } else {
        table.data = [] as any;
        table.reset();
      }
    }, { immediate: true });

    const anyForm = ref<any>();

    const machineSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("machine/find", value),
        query: params => {
          return model!.dispatch("machine/query", params)
        }
      }
    };
    const jobTickeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "JobTickeNo",
      valueField: "Id",
      columns: [
        {
          field: "JobTickeNo",
          title: "工單號碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("jobTicke/find", value), // eslint-disable-line
        query: params => {
          return model!.dispatch("jobTicke/query", params) // eslint-disable-line
        }
      }
    };
    const productSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "ProductName",
      valueField: "Id",
      columns: [
        {
          field: "ProductNo",
          title: "品號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "ProductName",
          title: "品名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("product/find", value),
        query: params => {
          if (jobId && jobId.value > 0) {
            (params as any).jobTicketId = jobId.value;
            console.log(params);
            return model!.dispatch("product/queryByJobTickte", params)
          } else {
            return model!.dispatch("product/query", params)
          }
        }
      }
    };

    const TF = [{ label: "是", value: true }, { label: "否", value: false }]

    const jobTickeProduct = async (data: any) => {
      if (data?.WorkOrder) {
        try {
          data.jobticketId = data.WorkOrder;
          jobId.value = data.WorkOrder;
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        }
      }
    }

    const selectProduct = async (data: any) => {
      try {
        const product = await model!.dispatch('product/find', data.ProductId);
        console.log(product);
        if (product) {
          console.log('product OK');
          data.ProductId = product.Id;
          data.WorkProductSize = product.ProductSize;
          data.WorkPaperType = product.TitlePaper;
          // data.WorkLight = product.ProductSize;
          // data.WorkTear = product.ProductSize;
          // data.WorkSound = product.ProductSize;
          data.WorkColor = product.Color;
          // data.WorkOdor = product.ProductSize;
        }
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {

      }
    }

    const customerProduct = async (data: any) => {
      if (data?.JobTickeItemId) {
        try {
          const temp = await model?.dispatch("jobTickeItem/find", data.JobTickeItemId)
          if (temp) {
            console.log(temp);
            data.ProductId = temp.Product?.Id;
            data.Name = temp.Product?.ProductName;
            data.WorkProductSize = temp.Product?.ProductSize;
            data.WorkPaperType = temp.Product?.TitlePaper;
            data.WorkColor = temp.Product?.Color;
            data.JobTickeId = temp.JobTicke.Id;
            data.JobTickeItemId = temp.Id;
            data.JobTickeNo = temp.JobTicke.JobTickeNo;
            data.JobTickeItemOrdinal = temp.Ordinal;
          }
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        }
      }
    }

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Product.ProductNo",
      valueField: "Id",
      formatText: (row) => row.Product ? `${row.Product.ProductNo ?? ''}` : row.Id,
      columns: [
        {
          field: "JobTicke.JobTickeNo",
          title: "工單號碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Ordinal",
          title: "項次",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.ProductNo",
          title: "品號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.SKU",
          title: "SKU",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.ProductName",
          title: "品名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("jobTickeItem/find", value),
        query: params => {
          if (table.formData.JobTickeId) params.condition = new Condition("JobTickeId", Operator.Equal, table.formData.JobTickeId).and(params.condition!);
          return model!.dispatch("jobTickeItem/query", params)
        }
      }
    };

    const customerSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("customer/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("customer/query", params) // eslint-disable-line
        }
      }
    };

    return {
      table,
      grid,
      gridOptions,
      anyForm,
      machineSelectorOptions,
      productSelectorOptions,
      model,
      TF,
      jobTickeSelectorOptions,
      jobTickeProduct,
      selectProduct,
      checkPaperType,
      selectorOptions,
      customerProduct,
      customerSelectorOptions,
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})

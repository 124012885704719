<template>
  <fieldset class="border rounded m-2 px-2 pt-2">
    <vxe-form v-if="!readType" class="" ref="anyForm" :data="table.formData" :rules="table.rules" titleAlign="right" @submit="table.save">
        <vxe-form-item title="產品" field="ProductId" :item-render="{}" span="5">
          <template #default="{ data }">
            <select-box v-bind="selectorOptions" v-model="data.ProductId" />
          </template>
        </vxe-form-item>
        <vxe-form-item title="數量" field="Quantity" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="number" v-model="data.Quantity" placeholder="請輸入數字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="單價" field="Price" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="float" v-model="data.Price" :digits="3" placeholder="請輸入數字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="金額" field="Amount" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="float" v-model="data.Amount" :digits="3" placeholder="請輸入數字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item :item-render="{}" span="3">
          <template #default>
            <vxe-button type="submit" status="primary" :content="table.isEditing ? '修改' : '確認'">
            </vxe-button>
            <vxe-button v-if="table.isEditing" type="button" @click="table.reset" status="danger" content="取消">
            </vxe-button>
          </template>
        </vxe-form-item>
    </vxe-form>
    <vxe-table class="mb-2" ref="grid" :data="table.data" v-bind="gridOptions.props">
      <vxe-column field="Product.ProductNo" title="品號" sortable>
      </vxe-column>
      <vxe-column field="Product.ProductName" title="品名" sortable>
      </vxe-column>
      <vxe-column field="Quantity" title="數量" sortable>
      </vxe-column>
      <vxe-column field="Price" title="單價" :formatter="({ cellValue }) => formatAmount(cellValue)" sortable>
      </vxe-column>
      <vxe-column field="Amount" title="金額" :formatter="({ cellValue }) => formatAmount(cellValue)" sortable>
      </vxe-column>
      <vxe-column v-if="!readType" title="操作" width="150">
        <template #default="{ row }">
          <div class="flex justify-content">
            <vxe-button @click="table.update(row)">編輯</vxe-button>
            <vxe-button @click="table.delete(row)">刪除</vxe-button>
          </div>
        </template>
      </vxe-column>
    </vxe-table>
  </fieldset>
</template>
<script lang="ts">
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps, VxeFormProps } from "vxe-table";
import { formatAmount } from '@/libs/utils'

export default defineComponent({
  name: "FlowTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    aid: Number,
    visible: Boolean,
    readType: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          multiple: true,
          defaultSort: []
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as any[],
      isLoading: false,
      isEditing: false,
      formData: {  } as any,
      selectedRow: null as any | null,
      // insert() {
      //   table.formData = { OutSource: false, Fee: 0, PayStatus: 0, Ordinal: 0 };
      //   table.selectedRow = null;
      //   table.isEditing = true;
      // },
      update: async (row: any) => {
        // console.log(row)        
        table.formData = { ...row };        
        table.selectedRow = row;
        table.isEditing = true;
      },
      delete(row: any) {
        grid.value.remove(row);
        table.reset();
      },
      submit() {
        emit('submit', grid.value.getTableData().fullData)
      },
      reset() {
        table.formData = {};
        table.formData.Amount = 0;
        table.formData.Quantity = 0;
        table.formData.Price = 0;
        table.selectedRow = null;
        table.isEditing = false;
      },
      async save() {
        table.formData.Product = null;
        if(!table.formData.ProductId)
          return CloudFun.send('error', { subject: '執行失敗', content: '請選擇產品' })
        if (table.formData.ProductId) {
          const temp = await model?.dispatch("product/find", table.formData.ProductId)
          table.formData.Product = { Id: temp.Id, ProductName: temp.ProductName, ProductNo: temp.ProductNo };
        }
        if(table.isEditing) {
          // 編輯確認
          Object.assign(table.selectedRow, table.formData);
        } else {
          // 新增確認
          await grid.value.insert(table.formData);
        }
        table.reset();
      },
      rules: {
        ProductNameSpecifications: [{ required: true }],
        Quantity: [{ required: true }],
        Price: [{ required: true }],
      }
    })

    watch(() => props.aid, async(value) => {
      if(value) {
        console.log(props.data)
        if(props.data && props.data.length > 0) {
          table.data = props.data;
          table.data.forEach(async(e: any) => {
            if (e.ProductId) {
              const temp = await model?.dispatch("product/find", e.ProductId)
              e.Product = { Id: temp.Id, ProductName: temp.ProductName, ProductNo: temp.ProductNo };
            }
          });
        } else {
          table.data = [] as any;
        }
        table.reset();
      } else {
        table.data = [] as any;
        table.reset();
      }
    }, { immediate: true });

    const anyForm = ref<any>();

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "ProductName",
      valueField: "Id",
      columns: [
        {
          field: "ProductNo",
          title: "品號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "ProductName",
          title: "品名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("product/find", value),
        query: params => {
          return model!.dispatch("product/query", params)
        }
      }
    };

    return {
      table,
      grid,
      gridOptions,
      anyForm,
      selectorOptions,
      model,
      formatAmount,
    }
  },
  methods: {
    getData() {          
      return this.grid.getTableData().fullData;
    }
  }
})
</script>

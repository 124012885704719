<template>
  <fieldset
    class="border rounded m-2 px-2 pt-2"
    :class="{ 'border-red-500': isError }"
  >
    <vxe-form
      v-if="!readType"
      class=""
      ref="anyForm"
      :data="table.formData"
      :rules="table.rules"
      titleAlign="right"
      @submit="table.save"
    >
      <vxe-form-item
        title="工單號碼"
        field="JobTickeId"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <select-box
            v-if="data"
            v-bind="jobTickeSelectorOptions"
            :focusRefresh="true"
            v-model="data.JobTickeId"
          />
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="品號"
        field="JobTickeItemId"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <select-box
            v-bind="selectorOptions"
            v-model="data.JobTickeItemId"
            :focusRefresh="true"
            @change="customerProduct(data)"
          />
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="項次"
        field="JobTickeItemOrdinal"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-input
            type="text"
            v-model="data.JobTickeItemOrdinal"
            placeholder="請輸入文字"
            clearable
          ></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="品名"
        field="ProductName"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-input
            type="text"
            v-model="data.ProductName"
            placeholder="請輸入文字"
            clearable
          ></vxe-input>
        </template>
      </vxe-form-item>
      <!-- <vxe-form-item title="日期" field="Date" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="date" v-model="data.Date" placeholder="請輸入日期" label-format="yyyy/MM/dd" clearable></vxe-input>
          </template>
        </vxe-form-item> -->
      <!-- <vxe-form-item title="品號" field="ProductNumber" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.ProductNumber" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item> -->
      <vxe-form-item
        title="規格/顏色"
        field="SKU"
        :item-render="{}"
        span="12"
      >
        <template #default="{ data }">
          <vxe-input
            type="text"
            v-model="data.SKU"
            placeholder="請輸入文字"
            clearable
          ></vxe-input>
        </template>
      </vxe-form-item>
      <!-- <vxe-form-item title="品檢員" field="Inspector" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-input type="text" v-model="data.Inspector" placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item> -->
      <vxe-form-item span="24">
        輔材
      </vxe-form-item>
      <!-- <vxe-form-item title="收縮膜" field="ShrinkFilm" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-select v-model="data.ShrinkFilm">
              <vxe-option v-for="item in TF" :key="item.value" :value="item.value" :label="`${item.label}`"></vxe-option>
            </vxe-select>
          </template>
        </vxe-form-item> -->
      <vxe-form-item
        title="收縮膜"
        field="ShrinkFilmType"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-select
            v-model="data.ShrinkFilmType"
            class="border rounded"
          >
            <vxe-option
              v-for="item in shrinkFilmType"
              :key="item.value"
              :value="item.value"
              :label="`${item.label}`"
            ></vxe-option>
          </vxe-select>
        </template>
      </vxe-form-item>
      <!-- <vxe-form-item title="糖果袋" field="CandyBag" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-select v-model="data.CandyBag">
              <vxe-option v-for="item in TF" :key="item.value" :value="item.value" :label="`${item.label}`"></vxe-option>
            </vxe-select>
          </template>
        </vxe-form-item>
        <vxe-form-item title="自黏袋" field="SealingBag" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-select v-model="data.SealingBag">
              <vxe-option v-for="item in TF" :key="item.value" :value="item.value" :label="`${item.label}`"></vxe-option>
            </vxe-select>
          </template>
        </vxe-form-item> -->
      <vxe-form-item
        title="袋裝"
        field="PackingBag"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-select
            v-model="data.PackingBag"
            class="border rounded"
          >
            <vxe-option
              v-for="item in packingBagType"
              :key="item.value"
              :value="item.value"
              :label="`${item.label}`"
            ></vxe-option>
          </vxe-select>
        </template>
      </vxe-form-item>
      <!-- <vxe-form-item title="PVC" field="PVC" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-select v-model="data.PVC">
              <vxe-option v-for="item in TF" :key="item.value" :value="item.value" :label="`${item.label}`"></vxe-option>
            </vxe-select>
          </template>
        </vxe-form-item>
        <vxe-form-item title="POF" field="POF" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-select v-model="data.POF">
              <vxe-option v-for="item in TF" :key="item.value" :value="item.value" :label="`${item.label}`"></vxe-option>
            </vxe-select>
          </template>
        </vxe-form-item>
        <vxe-form-item title="PE" field="PE" :item-render="{}" span="6">
          <template #default="{ data }">
            <vxe-select v-model="data.PE">
              <vxe-option v-for="item in TF" :key="item.value" :value="item.value" :label="`${item.label}`"></vxe-option>
            </vxe-select>
          </template>
        </vxe-form-item> -->
      <vxe-form-item
        title="切台"
        field="Cutter"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-select
            v-model="data.Cutter"
            class="border rounded"
          >
            <vxe-option
              v-for="item in TF"
              :key="item.value"
              :value="item.value"
              :label="`${item.label}`"
            ></vxe-option>
          </vxe-select>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="彩盒"
        field="ColorBox"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-select
            v-model="data.ColorBox"
            class="border rounded"
          >
            <vxe-option
              v-for="item in TF"
              :key="item.value"
              :value="item.value"
              :label="`${item.label}`"
            ></vxe-option>
          </vxe-select>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="吊卡"
        field="Elevator"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-select
            v-model="data.Elevator"
            class="border rounded"
          >
            <vxe-option
              v-for="item in TF"
              :key="item.value"
              :value="item.value"
              :label="`${item.label}`"
            ></vxe-option>
          </vxe-select>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="紙卡"
        field="PaperCard"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-select
            v-model="data.PaperCard"
            class="border rounded"
          >
            <vxe-option
              v-for="item in TF"
              :key="item.value"
              :value="item.value"
              :label="`${item.label}`"
            ></vxe-option>
          </vxe-select>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="貼紙"
        field="Sticker"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-select
            v-model="data.Sticker"
            class="border rounded"
          >
            <vxe-option
              v-for="item in stickerType"
              :key="item.value"
              :value="item.value"
              :label="`${item.label}`"
            ></vxe-option>
          </vxe-select>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="內盒"
        field="InnerBox"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-select
            v-model="data.InnerBox"
            class="border rounded"
          >
            <vxe-option
              v-for="item in TF"
              :key="item.value"
              :value="item.value"
              :label="`${item.label}`"
            ></vxe-option>
          </vxe-select>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="紙箱"
        field="Carton"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-select
            v-model="data.Carton"
            class="border rounded"
          >
            <vxe-option
              v-for="item in TF"
              :key="item.value"
              :value="item.value"
              :label="`${item.label}`"
            ></vxe-option>
          </vxe-select>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="離型紙"
        field="ReleasePaper"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-select
            v-model="data.ReleasePaper"
            class="border rounded"
          >
            <vxe-option
              v-for="item in TF"
              :key="item.value"
              :value="item.value"
              :label="`${item.label}`"
            ></vxe-option>
          </vxe-select>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="圓卡"
        field="RoundCard"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-select
            v-model="data.RoundCard"
            class="border rounded"
          >
            <vxe-option
              v-for="item in TF"
              :key="item.value"
              :value="item.value"
              :label="`${item.label}`"
            ></vxe-option>
          </vxe-select>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="折角袋"
        field="TuckBag"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-select
            v-model="data.TuckBag"
            class="border rounded"
          >
            <vxe-option
              v-for="item in TF"
              :key="item.value"
              :value="item.value"
              :label="`${item.label}`"
            ></vxe-option>
          </vxe-select>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="白銅卡"
        field="WhiteCopperCard"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-select
            v-model="data.WhiteCopperCard"
            class="border rounded"
          >
            <vxe-option
              v-for="item in TF"
              :key="item.value"
              :value="item.value"
              :label="`${item.label}`"
            ></vxe-option>
          </vxe-select>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="托盒"
        field="Tray"
        :item-render="{}"
        span="6"
      >
        <template #default="{ data }">
          <vxe-select
            v-model="data.Tray"
            class="border rounded"
          >
            <vxe-option
              v-for="item in TF"
              :key="item.value"
              :value="item.value"
              :label="`${item.label}`"
            ></vxe-option>
          </vxe-select>
        </template>
      </vxe-form-item>

      <vxe-form-item span="24">
        檢查時段
      </vxe-form-item>
      <vxe-form-item
        title="首件"
        field="First"
        :item-render="{}"
        span="8"
      >
        <template #default="{ data }">
          <vxe-select
            v-model="data.First"
            class="border rounded"
          >
            <vxe-option
              v-for="item in TF"
              :key="item.value"
              :value="item.value"
              :label="`${item.label}`"
            ></vxe-option>
          </vxe-select>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="中段"
        field="Middle"
        :item-render="{}"
        span="8"
      >
        <template #default="{ data }">
          <vxe-select
            v-model="data.Middle"
            class="border rounded"
          >
            <vxe-option
              v-for="item in TF"
              :key="item.value"
              :value="item.value"
              :label="`${item.label}`"
            ></vxe-option>
          </vxe-select>
        </template>
      </vxe-form-item>
      <vxe-form-item
        title="後段"
        field="Last"
        :item-render="{}"
        span="8"
      >
        <template #default="{ data }">
          <vxe-select
            v-model="data.Last"
            class="border rounded"
          >
            <vxe-option
              v-for="item in TF"
              :key="item.value"
              :value="item.value"
              :label="`${item.label}`"
            ></vxe-option>
          </vxe-select>
        </template>
      </vxe-form-item>
      <vxe-form-item
        :item-render="{}"
        span="3"
      >
        <template #default>
          <vxe-button
            type="submit"
            status="primary"
            :content="table.isEditing ? '修改' : '確認'"
          >
          </vxe-button>
          <vxe-button
            v-if="table.isEditing"
            type="button"
            @click="table.reset"
            status="danger"
            content="取消"
          >
          </vxe-button>
        </template>
      </vxe-form-item>
    </vxe-form>
    <vxe-table
      class="mb-2"
      ref="grid"
      :data="table.data"
      v-bind="gridOptions.props"
    >
      <!-- <vxe-column field="Number" title="編號">
      </vxe-column> -->
      <!-- <vxe-column field="JobTickeItem.ProductNo" title="品號" sortable>
      </vxe-column>
      <vxe-column field="JobTickeItem.ProductName" title="品名" sortable>
      </vxe-column> -->
      <vxe-column
        type="expand"
        width="60"
        :slots="{ content: 'content' }"
      >
        <template #content="{ row }">
          <table class="w-full table-auto w-auto border-collapse border border-gray-400">
            <thead>
              <tr>
                <th
                  class="border border-gray-400 bg-gray-200 py-2 px-4 text-center"
                  colspan="4"
                >
                  詳細資料
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="grid grid-cols-4">
                <td class="border border-gray-400 bg-gray-200 py-2 px-4">規格/顏色</td>
                <td class="border border-gray-400 py-2 px-4">{{ row.SKU }}</td>
                <td class="border border-gray-400 bg-gray-200 py-2 px-4">收縮膜</td>
                <td class="border border-gray-400 py-2 px-4">{{ row.ShrinkFilmType }}</td>
                <!-- <td class="border border-gray-400 bg-gray-200 py-2 px-4">糖果袋</td>
                <td class="border border-gray-400 py-2 px-4">{{ row.CandyBag ? "是" : "否" }}</td> -->
                <!-- <td class="border border-gray-400 bg-gray-200 py-2 px-4">自黏袋</td>
                <td class="border border-gray-400 py-2 px-4">{{ row.SealingBag ? "是" : "否" }}</td> -->
                <td class="border border-gray-400 bg-gray-200 py-2 px-4">袋裝</td>
                <td class="border border-gray-400 py-2 px-4">{{ row.PackingBag }}</td>
                <!-- <td class="border border-gray-400 bg-gray-200 py-2 px-4">PVC</td>
                <td class="border border-gray-400 py-2 px-4">{{ row.PVC ? "是" : "否" }}</td>
                <td class="border border-gray-400 bg-gray-200 py-2 px-4">POF</td>
                <td class="border border-gray-400 py-2 px-4">{{ row.POF ? "是" : "否" }}</td>
                <td class="border border-gray-400 bg-gray-200 py-2 px-4">PE</td>
                <td class="border border-gray-400 py-2 px-4">{{ row.PE ? "是" : "否" }}</td> -->
                <td class="border border-gray-400 bg-gray-200 py-2 px-4">切台</td>
                <td class="border border-gray-400 py-2 px-4">{{ row.Cutter ? "是" : "否" }}</td>
                <td class="border border-gray-400 bg-gray-200 py-2 px-4">彩盒</td>
                <td class="border border-gray-400 py-2 px-4">{{ row.ColorBox ? "是" : "否" }}</td>
                <td class="border border-gray-400 bg-gray-200 py-2 px-4">吊卡</td>
                <td class="border border-gray-400 py-2 px-4">{{ row.Elevator ? "是" : "否" }}</td>
                <td class="border border-gray-400 bg-gray-200 py-2 px-4">紙卡</td>
                <td class="border border-gray-400 py-2 px-4">{{ row.PaperCard ? "是" : "否" }}</td>
                <td class="border border-gray-400 bg-gray-200 py-2 px-4">貼紙</td>
                <td class="border border-gray-400 py-2 px-4">{{ row.Sticker }}</td>
                <td class="border border-gray-400 bg-gray-200 py-2 px-4">內盒</td>
                <td class="border border-gray-400 py-2 px-4">{{ row.InnerBox ? "是" : "否" }}</td>
                <td class="border border-gray-400 bg-gray-200 py-2 px-4">紙箱</td>
                <td class="border border-gray-400 py-2 px-4">{{ row.Carton ? "是" : "否" }}</td>
                <td class="border border-gray-400 bg-gray-200 py-2 px-4">離型紙</td>
                <td class="border border-gray-400 py-2 px-4">{{ row.ReleasePaper ? "是" : "否" }}</td>
                <td class="border border-gray-400 bg-gray-200 py-2 px-4">圓卡</td>
                <td class="border border-gray-400 py-2 px-4">{{ row.RoundCard ? "是" : "否" }}</td>
                <td class="border border-gray-400 bg-gray-200 py-2 px-4">折角袋</td>
                <td class="border border-gray-400 py-2 px-4">{{ row.TuckBag ? "是" : "否" }}</td>
                <td class="border border-gray-400 bg-gray-200 py-2 px-4">白銅卡</td>
                <td class="border border-gray-400 py-2 px-4">{{ row.WhiteCopperCard ? "是" : "否" }}</td>
                <td class="border border-gray-400 bg-gray-200 py-2 px-4">托盒</td>
                <td class="border border-gray-400 py-2 px-4">{{ row.Tray ? "是" : "否" }}</td>
                <td class="border border-gray-400 bg-gray-200 py-2 px-4">首件</td>
                <td class="border border-gray-400 py-2 px-4">{{ row.First ? "是" : "否" }}</td>
                <td class="border border-gray-400 bg-gray-200 py-2 px-4">中段</td>
                <td class="border border-gray-400 py-2 px-4">{{ row.Middle ? "是" : "否" }}</td>
                <td class="border border-gray-400 bg-gray-200 py-2 px-4">後段</td>
                <td class="border border-gray-400 py-2 px-4">{{ row.Last ? "是" : "否" }}</td>
                <td class="border border-gray-400 bg-gray-200 py-2 px-4">品檢員</td>
                <td class="border border-gray-400 py-2 px-4">{{ row.Inspector }}</td>
              </tr>
            </tbody>
          </table>
        </template>
      </vxe-column>
      <vxe-column
        field="Date"
        title="日期"
        :formatter="({ cellValue }) => formatDate(cellValue, 'yyyy/MM/dd')"
      >
      </vxe-column>
      <vxe-column title="項次">
        <template #default="{ $rowIndex }">
          {{ $rowIndex + 1 }}
        </template>
      </vxe-column>
      <vxe-column
        field="ProductName"
        title="品名"
        sortable
      >
      </vxe-column>
      <vxe-column
        field="ProductNumber"
        title="品號"
        sortable
      >
      </vxe-column>
      <vxe-column
        v-if="!readType"
        title="操作"
        width="150"
      >
        <template #default="{ row }">
          <div class="flex justify-content">
            <vxe-button @click="table.update(row)">編輯</vxe-button>
            <vxe-button @click="table.delete(row)">刪除</vxe-button>
          </div>
        </template>
      </vxe-column>
    </vxe-table>
  </fieldset>
</template>
<script lang="ts">
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps, VxeFormProps } from "vxe-table";
import formatDate from 'xe-utils/toDateString';

export default defineComponent({
  name: "FlowTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    aid: Number,
    contentType: Number,
    visible: Boolean,
    readType: Boolean,
    isError: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          multiple: true,
          defaultSort: []
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as any[],
      isLoading: false,
      isEditing: false,
      formData: {} as any,
      selectedRow: null as any | null,
      // insert() {
      //   table.formData = { OutSource: false, Fee: 0, PayStatus: 0, Ordinal: 0 };
      //   table.selectedRow = null;
      //   table.isEditing = true;
      // },
      update(row: any) {
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;
      },
      delete(row: any) {
        grid.value.remove(row);
        table.reset();
      },
      submit() {
        emit('submit', grid.value.getTableData().fullData)
      },
      reset() {
        table.formData = {} as any;
        table.selectedRow = null;
        table.formData.ShrinkFilm = false;
        table.formData.CandyBag = false;
        table.formData.SealingBag = false;
        table.formData.PackingBag = false;
        table.formData.PVC = false;
        table.formData.POF = false;
        table.formData.PE = false;
        table.formData.Cutter = false;
        table.formData.ColorBox = false;
        table.formData.Elevator = false;
        table.formData.PaperCard = false;
        table.formData.Sticker = "貼紙";
        table.formData.InnerBox = false;
        table.formData.Carton = false;
        table.formData.ReleasePaper = false;
        table.formData.RoundCard = false;
        table.formData.TuckBag = false;
        table.formData.WhiteCopperCard = false;
        table.formData.Tray = false;
        table.formData.First = false;
        table.formData.Middle = false;
        table.formData.Last = false;
        if (props.contentType == 17) {
          table.formData.Category = '標籤';
        }
        table.isEditing = false;
      },
      async save() {

        // table.formData.JobTickeItem = null;
        // if(!table.formData.JobTickeItemId)
        //   return CloudFun.send('error', { subject: '執行失敗', content: '請選擇產品' })
        // if (table.formData.JobTickeItemId) {
        //   const temp = await model?.dispatch("jobTickeItem/find", table.formData.JobTickeItemId)
        //   table.formData.JobTickeItem = { Id: temp.Id, ProductName: temp.Product.ProductName, ProductNo: temp.Product.ProductNo };
        // }

        if (table.isEditing) {
          // 編輯確認
          Object.assign(table.selectedRow, table.formData);
        } else {
          // 新增確認
          await grid.value.insert(table.formData);
        }
        table.reset();
      },
      rules: {
        Number: [{ required: true }],
        Name: [{ required: true }],
        Date: [{ required: true }],
      }
    })

    watch(() => props.aid, async (value) => {
      if (value) {
        //console.log(props.contentType)
        if (props.data && props.data.length > 0) {
          table.data = props.data;
          // table.data.forEach(async (e: any) => {
          //   if(e.JobTickeItemId) {
          //     const temp = await model?.dispatch("jobTickeItem/find", e.JobTickeItemId);
          //     e.JobTickeItem = { Id: temp.Id, ProductName: temp.Product.ProductName, ProductNo: temp.Product.ProductNo };
          //   }
          // });
        } else {
          table.data = [] as any;
        }
        table.reset();
      } else {
        table.data = [] as any;
        table.reset();
      }
    }, { immediate: true });

    const anyForm = ref<any>();

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Product.ProductNo",
      valueField: "Id",
      formatText: (row) => row.Product ? `${row.Product.ProductNo ?? ''}` : row.Id,
      columns: [
        {
          field: "JobTicke.JobTickeNo",
          title: "工單號碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Ordinal",
          title: "項次",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.ProductNo",
          title: "品號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.SKU",
          title: "SKU",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Product.ProductName",
          title: "品名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("jobTickeItem/find", value),
        query: params => {
          if (table.formData.JobTickeId) params.condition = new Condition("JobTickeId", Operator.Equal, table.formData.JobTickeId).and(params.condition!);
          return model!.dispatch("jobTickeItem/query", params)
        }
      }
    };

    const TF = [{ label: "是", value: true }, { label: "否", value: false }]

    const colorBoxCategory = [
      { label: "包裝盒", value: "包裝盒" },
      { label: "車型盒", value: "車型盒" },
      { label: "切台盒", value: "切台盒" },
      { label: "內盒", value: "內盒" },
      { label: "托盒", value: "托盒" },
      { label: "吊盒", value: "吊盒" },
    ]

    const cartonCategory = [
      { label: "外箱", value: "外箱" },
      { label: "內箱", value: "內箱" },
      { label: "其他", value: "其他" },
    ]

    const shrinkFilmType = [
      { label: "PVC收縮膜", value: "PVC收縮膜" },
      { label: "POF收縮膜", value: "POF收縮膜" },
      { label: "PE收縮膜", value: "PE收縮膜" },
    ]

    const stickerType = [
      { label: "貼紙", value: "貼紙" },
      { label: "條碼貼紙", value: "條碼貼紙" },
      { label: "透明圓貼", value: "透明圓貼" },
      { label: "客供貼紙", value: "客供貼紙" },
    ]

    const packingBagType = [
      { label: "糖果袋", value: "糖果袋" },
      { label: "打孔自黏袋", value: "打孔自黏袋" },
      { label: "平口塑膠袋", value: "平口塑膠袋" },
      { label: "空白自黏袋", value: "空白自黏袋" },
      { label: "PE折角袋", value: "PE折角袋" },
    ]


    const customerProduct = async (data: any) => {
      if (data?.JobTickeItemId) {
        try {
          const temp = await model?.dispatch("jobTickeItem/find", data.JobTickeItemId)
          if (temp) {
            data.ProductName = temp.Product?.ProductName;
            data.ProductNumber = temp.Product?.ProductNo;
            data.SKU = temp.Product?.ProductSize + ' / ' + temp.Product?.Color;
            data.JobTickeId = temp.JobTicke.Id;
            data.JobTickeItemId = temp.Id;
            data.JobTickeNo = temp.JobTicke.JobTickeNo;
            data.JobTickeItemOrdinal = temp.Ordinal;
          }
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        }
      }
    }

    const jobTickeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "JobTickeNo",
      valueField: "Id",
      columns: [
        {
          field: "JobTickeNo",
          title: "工單號碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("jobTicke/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("jobTicke/query", params) // eslint-disable-line
        }
      }
    };

    return {
      table,
      grid,
      gridOptions,
      anyForm,
      selectorOptions,
      model,
      TF,
      colorBoxCategory,
      cartonCategory,
      formatDate,
      customerProduct,
      jobTickeSelectorOptions,
      shrinkFilmType,
      stickerType,
      packingBagType,
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})
</script>
